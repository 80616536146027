import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import profcast from 'img/profcast-image.jpg'
import profcastKerst from 'img/profcast-kerst.jpeg'

const Box = styled.div`
  background: ${(props) => props.theme.color.face.light};
  padding: 0 30px 30px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
`

const Audio = styled.audio`
  width: 100%;
`

const PodcastPage = () => {
  const { podcast } = useStaticQuery(graphql`
    {
      podcast: allBuzzsproutPodcastEpisode(
        sort: { order: DESC, fields: published_at }
      ) {
        edges {
          node {
            id
            title
            audio_url
          }
        }
      }
    }
  `)

  let number = 1

  return podcast.edges.map(({ node }, index) => (
    <>
      <div
        className={` ${node.id} col-lg-4 col-md-6 mb-3 d-flex flex-wrap `}
        key={node.id}
      >
        <Box>
          {node.id == 'Buzzsprout__PodcastEpisode__6990694' ? (
            <img src={profcastKerst} width="100%" className="mb-3" />
          ) : node.id == 'Buzzsprout__PodcastEpisode__11926940' ? (
            <img src={profcastKerst} width="100%" className="mb-3" />
          ) : node.id == 'Buzzsprout__PodcastEpisode__14191805' ? (
            <img src={profcastKerst} width="100%" className="mb-3" />
          ) : node.id == 'Buzzsprout__PodcastEpisode__9724169' ? (
            <img src={profcastKerst} width="100%" className="mb-3" />
          ) : (
            <img src={profcast} width="100%" className="mb-3" />
          )}
          <h2 className="mb-4">
            Prof&ProfCast aflevering {podcast.edges.length - index}:{' '}
            {node.title}
          </h2>
          <Audio controls>
            <source src={node.audio_url} />
          </Audio>
        </Box>
      </div>
    </>
  ))
}

export default PodcastPage
