/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import ParseContent from 'components/shared/ParseContent'
import Image from 'components/shared/Image'
import ImageBackground from 'components/shared/ImageBackground'
import CustomLink from 'components/shared/CustomLink'

// Elements
import ButtonAlt from 'components/elements/ButtonAlt'
import Video from 'components/elements/Video'

// Third Party
import styled, { css } from 'styled-components'

// Images
import linkedinWhite from 'img/prof_linkedin_white.svg'
import emailWhite from 'img/prof_email_white.svg'
import phoneWhite from 'img/prof_phone_white.svg'

const Hero = styled.div`
  position: relative;
  background-color: ${props => props.theme.color.face.main};
  padding-top: 95px;
  height: 403px;

  @media (max-width: 1249px) {
    height: 403px
  }

  @media (max-width: 991px) {
    height: 100%;
    min-height: 220px;
  }
`

const StyledImageBackground = styled(ImageBackground)`
  position: absolute !important;
  height: 100%;
  width: 44%;
  bottom: 0;
  right: 0;
  min-width: 592px;

  @media screen and (max-width: 1249px) {
    min-width: 400px;
    width: 40%;
  }

  @media screen and (max-width: 1199px) {
    min-width: 400px;
    width: 60%;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }

  ${props => props.video && css`
    display: flex;
    align-items: center;
  `}
`

const StyledImage = styled(Image)`

  ${props => props.live ? css`
    position: absolute !important;
    height: 356px;
    width: 680px;
    bottom: 0;
    right: 140px;

    @media screen and (max-width: 1199px) {
      height: 240px;
      width: 480px;
      right: 180px;
    }
  ` : css`
    position: absolute !important;
    height: 496px;
    width: 585px;
    bottom: 0;
    right: 0;

    @media screen and (max-width: 1199px) {
      height: 380px;
      width: 480px;
    }
  `}
`

const Content = styled(ParseContent)`
  color: ${props => props.theme.color.text.light};
  position: relative;

  h1 {
    ${props => props.home ? css`
      position: absolute;
      left: 200px;
      top: 0;
      white-space: nowrap;

      @media screen and (max-width: 991px) {
        position: relative;
        left: 0;
        top: 0;
      }
    ` : css`
      position: relative;
      top: 80px;

      @media screen and (max-width: 1199px) {
        top: -10px;
      }

      @media screen and (max-width: 991px) {
        top: 0;
      }
    `}
    font-size: ${props => props.theme.font.size.xxml};
  }
  
  p {
    line-height: 35px;
    font-size: ${props => props.theme.font.size.m};
    font-family: ${props => props.theme.font.family.secondary};

    ${props => props.contact ? css`
      font-weight: ${props => props.theme.font.weight.xl};
    ` : css`
      font-weight: ${props => props.theme.font.weight.xxs};
    `}
  }

  strong {
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  ${props => props.home ? css`

  ` : css`
    top: -60px;

    @media screen and (max-width: 1199px) {
      top: 0;
    }
  `}
`

const ImageContainer = styled.div``

const ContentContainer = styled.div`
  position: relative;
  top: 60px;

  @media screen and (max-width: 991px) {
    top: 0;
  }
`

const VideoContainer = styled.div`
  width: 100%;
  position: relative;
  top: 150px;
  left: -150px;

  @media screen and (max-width: 991px) {
    top: 0;
    left: 0;
  }
`

const Contact = styled.div`
  font-family: ${props => props.theme.font.family.secondary};
  color: ${props => props.theme.color.text.light};

  img {
    width: 25px;
    height: 25px;
  }
`

const HeroAlt = ({ contact, live, video, home, fields }) => {
  const {
    background,
    videos: {
      nodes: videos
    }
  } = useStaticQuery(graphql`{
    background: file(relativePath: {eq: "prof_header_4.png"}) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    videos: allWordpressWpVideo(sort: {fields: date, order: DESC}) {
      nodes {
        ...videoPreview
      }
    }
  }`)

  return (
    <Hero>
      <div className="container d-flex align-items-center h-100 py-5">
        <div className="row mx-2 w-100">
          <div className="col-lg-5 d-flex align-items-lg-center">
            <ContentContainer>
              <Content contact={contact} home={home} content={fields.description} />
              {fields.link && (
                <ButtonAlt className="mt-4" to={fields.link.url}>
                  {fields.link.title}
                </ButtonAlt>
              )}

              {contact && (
               <Contact className="pt-4">
                  <div>
                    <CustomLink className="d-flex align-items-center" external newPage to={contact.bernard_linkedin.url}>
                      <img className="mr-3" src={linkedinWhite} alt="" />
                      {contact.bernard_linkedin.title}
                    </CustomLink>
                  </div>
                  <div className="my-1 ml-3 pl-4">&</div>
                  <div className="mb-4">
                    <CustomLink className="d-flex align-items-center" external newPage to={contact.jeroen_linkedin.url}>
                      <img className="mr-3" src={linkedinWhite} alt="" />
                      {contact.jeroen_linkedin.title}
                    </CustomLink>
                  </div>
                  <div className="mb-3">
                    <CustomLink className="d-flex align-items-center" external newPage to={contact.email.url}>
                      <img className="mr-3" src={emailWhite} alt="" />
                      {contact.email.title}
                    </CustomLink>
                  </div>
                  <div className="mb-2">
                    <CustomLink className="d-flex align-items-center" external newPage to={contact.phone.url}>
                      <img className="mr-3" src={phoneWhite} alt="" />
                      {contact.phone.title}
                    </CustomLink>
                  </div>
                  <div>
                    <CustomLink className="d-flex align-items-center" external newPage to={contact.phone_2.url}>
                      <img className="mr-3" src={phoneWhite} alt="" />
                      {contact.phone_2.title}
                    </CustomLink>
                  </div>
               </Contact>
              )}
            </ContentContainer>
          </div>
          <ImageContainer>
            <StyledImageBackground video={video} local src={background} aboveFold>
              {video && (
                <VideoContainer>
                  <Video delay={0.6} spotlight video={videos[0]} />
                </VideoContainer>
              )}

              {fields.image && (
                <StyledImage live={live} src={fields.image} aboveFold />
              )}
            </StyledImageBackground>
          </ImageContainer>
          {video && (

            <div className="col-12 d-lg-none">
              <VideoContainer className="d-flex pt-5 align-items-center justify-content-center">
                <Video delay={0.6} spotlight video={videos[0]} />
              </VideoContainer>
            </div>
          )}
        </div>
      </div>
    </Hero>
  )
}

export default HeroAlt