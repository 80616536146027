/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Elements
import HeroAlt from 'components/elements/HeroAlt'

// Components
import SEO from 'components/shared/SEO'
import Layout from 'components/layout/Layout'
import ParseContent from 'components/shared/ParseContent'
import BuzzSproutPodCast from 'components/elements/BuzzSproutPodCast'



const PageTemplate = ({
  data: {
    page: { path, yoast_meta: yoast, acf },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={yoast} path={path} />

      <HeroAlt fields={acf.banner} />

      <div style={{ paddingBottom: '150px', backgroundColor: '#F2F2F2' }}>
        <div className="container py-lg-3">
          <div className="row py-5">
            <div className="col-12 d-flex flex-wrap">
                <BuzzSproutPodCast />
            </div>
          </div>
        </div>
      </div>

    </Layout>
  )
}

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      ...podcastPageFragment
    }
  }
`

export default PageTemplate
